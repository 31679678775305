.App {
  text-align: center;
}

.small-img img {
  height: 4rem;
  border-radius: 0.5rem;
}
.small-img-pp img {
  width: 3.5rem;
  border-radius: 50%;
}